import Vue from "vue";
import router from "./router";
import store from "./store";
import iview from "view-design";
import 'view-design/dist/styles/iview.css';
import ElementUI from "element-ui";
import "@/icons";
import "element-ui/lib/theme-chalk/index.css";
import "@/element-ui-theme";
import "@/assets/scss/index.less";
import "@/assets/builder.less";
import httpRequest from "@/utils/httpRequest";
import { isAuth } from "@/utils";
import { Loading } from "element-ui";

Vue.config.productionTip = false;

Vue.prototype.$http = httpRequest; // ajax请求方法
Vue.prototype.isAuth = isAuth; // 权限方法
//全局遮罩层
Vue.prototype.loading = "";
Vue.prototype.$mask = () => {
  Vue.prototype.loading = Loading.service({
    background: "rgba(255,255,255,0.1)",
  });
  return {
    close: () => {
      Vue.prototype.loading.close();
    },
  };
};

Vue.use(ElementUI);
Vue.use(iview);


new Vue({
  router,
  store,
  render: (h) => h("router-view"),
}).$mount("#app");
