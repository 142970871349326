import Vue from "vue";
import router from "@/router";
import store from "@/store";
import gcoord from "@/assets/gcoord";

/**
 * 获取uuid
 */
export function getUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    return (c === "x" ? (Math.random() * 16) | 0 : "r&0x3" | "0x8").toString(
      16
    );
  });
}
/**
 * 获取token
 */
export const getToken = () => {
  let str = "";
  const loginData = localStorage.getItem("login-data");
  if (loginData) {
    str = JSON.parse(loginData).token;
  }
  return str;
};

/**
 * 是否有权限
 * @param {*} key
 */
export function isAuth(key) {
  return (
    JSON.parse(sessionStorage.getItem("permissions") || "[]").indexOf(key) !==
      -1 || false
  );
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate(data, id = "id", pid = "parentId") {
  var res = [];
  var temp = {};
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i];
  }
  for (var k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]]["children"]) {
        temp[data[k][pid]]["children"] = [];
      }
      if (!temp[data[k][pid]]["_level"]) {
        temp[data[k][pid]]["_level"] = 1;
      }
      data[k]["_level"] = temp[data[k][pid]]._level + 1;
      temp[data[k][pid]]["children"].push(data[k]);
    } else {
      res.push(data[k]);
    }
  }
  return res;
}

/**
 * 清除登录信息
 */
export function clearLoginInfo() {
  localStorage.removeItem("login-data");
  store.commit("common/removeData");
  router.options.isAddDynamicMenuRoutes = false;
  router.replace({ name: "login" });
  delCookie("hide_stop_equipment_warn");
}

/**
 * @description 根据权限菜单进行路由跳转(默认跳转第一个)
 * @param { Array } array 需要处理的菜单数组
 * @returns { String } 跳转路由 name
 */
export const recursionMenus = (array) => {
  let _menus = array;
  if (_menus[0].list && _menus[0].list.length) {
    return recursionMenus(_menus[0].list);
  } else {
    return _menus[0].url;
  }
};

/**
 * @description 判断数据类型
 * @param { any } data 接受各种数据类型
 * @returns { String } 传入数据类型
 */
export const type = (data) => {
  let every = Object.prototype.toString.call(data);
  return every.substring(8, every.length - 1).toLowerCase();
};

export const exportTable = (res, title) => {
  let link = document.createElement("a");
  let blob = new Blob([res], { type: "application/vnd.ms-excel" });
  let url = window.URL.createObjectURL(blob);
  link.download = (title || "导出") + ".xls";
  link.href = url;
  link.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
  }, 500);
};

// **
// * cookie中存值
// * */
export const setCookie = (c_name, value, msec) => {
  let exdate = new Date();
  exdate.setTime(exdate.getTime() + msec);
  document.cookie =
    c_name +
    "=" +
    escape(value) +
    (msec == null ? "" : ";expires=" + exdate.toGMTString());
};
/**
 * cookie中取值
 * */
export const getCookie = (name) => {
  let arr,
    reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if ((arr = document.cookie.match(reg))) return arr[2];
  else return null;
};
/**
 * 清除指定cookie值
 * */
export const delCookie = (name) => {
  let exp = new Date();
  exp.setTime(exp.getTime() - 1);
  let cval = getCookie(name);
  if (cval != null)
    document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
};

/**
 * @description  高德坐标转GPS
 * @param { Array } array  二维数组
 */
export const AmapToGPS = (array) => {
  let _arr = array;
  for (let i = 0; i < _arr.length; i++) {
    _arr[i] = gcoord.transform(_arr[i], gcoord.GCJ02, gcoord.WGS84);
  }
  return _arr;
};